$input-label-color: rgb(0, 0, 0) !default;
$input-bg-color: rgb(255, 255, 255) !default;
$input-border-color: rgb(0, 0, 0) !default;
$input-font: Helvetica Neue, Helvetica, Arial, serif !default;
$input-color: rgb(0, 0, 0) !default;

.input-radio {
  input {
    opacity: 0;

    &:checked + label {
      &:before {
        -webkit-box-shadow: 0 0 0 3px $input-bg-color inset;
        background-color: $input-border-color;
        box-shadow: inset 0 0 0 3px $input-bg-color;
      }
    }
  }

  label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 100%;

    &::before {
      -webkit-transition: all .2s ease-in-out;
      -webkit-box-shadow: 0 0 0 20px $input-bg-color inset;

      background-color: $input-bg-color;
      border: 2px solid $input-border-color;
      border-radius: 100%;
      box-shadow: inset 0 0 0 20px $input-bg-color;
      content: '';
      display: inline-block;
      height: rem-calc(18);
      margin-right: rem-calc(16);
      transition: 0.2s ease-in-out;
      vertical-align: middle;
      width: rem-calc(18);
    }
  }

  .modal-trigger {
    background: url('/static/assets/images/hawa_icon_auge.svg');
    cursor: pointer;
    float: right;
    height: rem-calc(29);
    margin-left: auto;
    width: rem-calc(29);

    &:active {
      background-color: $graph-display-nav-item-color;

      svg * {
        stroke: $graph-display-nav-item-background-color;
      }
    }

    &:hover {
      background: url('/static/assets/images/hawa_icon_auge_hover.svg');
    }
  }


  &.disabled {
    opacity: 0.5;

    input {
      background-color: transparent;
      cursor: default;
    }
  }
}




