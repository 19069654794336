$comp-color-profile-label-color: #003c78 !default;

.comp-color-profile-selector {
  .color-profile-label {
    color: $comp-color-profile-label-color;
    display: inline-block;
    line-height: 1.2;
    width: 100%;
  }

  .spacer {
    @include hide-for(large up);
  }

  @include breakpoint(large up) {
    .cell {
      position: relative;
    }

    .color-profile-label {
      bottom: 0;
      display: inline;
      line-height: 1.7;
      position: absolute;
      vertical-align: middle;
    }
  }
}
